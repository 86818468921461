const imageIds = [
  "1-bZWuO-TnJYQWfcat1jCxFfOQ7UOBarb",
  "10FGJLNGJ6dBInEoQQXq84s7hswBMPYR3",
  "10n7SErP91Dq60H-TEVQ7iTv0hiMVHZMf",
  "11B37gFXFzQcadV-HAN_e2Yzc0FVyfOF5",
  "134IX5_PWl6RE6PxtE4dtgFSVazuIwI2S",
  "14T2BBS7M2KISSrYyqJ8n6HoonmyN_xqM",
  "15ci1JIMePg2l9HmPCm1e8a0etZhdxqtV",
  "17U80_gsxR9o7Thqu8Tp85fnXiT_PUy8u",
  "1BK4MgXGqO0Yc5ftxoYptomyTtznyjzmC",
  "1BPi3MNxD36tzAej4fiQYYOhFO-3Nkilz",
  "1B_vWpuky-jMiF50IqzglexGWNg__w78C",
  "1BkfsHc4o990T-hMvHXM6gKNqOATXAg4e",
  "1CjUrkcukMRqdudxNuP4lKbPJ5mHLtxwY",
  "1E8s_iXZ_rc1ElwDVXYBELFTo4RkeJtx0",
  "1EAlRjxEoe3-u_3Dv-WI9rVONDxlG_uI8",
  "1ETgAOEi0B9PfaN2TMrmsN9_LSL6yDA_Q",
  "1EqgbnJLOJEbwz88ciMsRc8jVyvwxYWhS",
  "1EuxwlbZKAZH09tD4HPrOvI7h3GBez4_N",
  "1FhV9qWvr5s4iO5i2rmdUTtoWCF_tWtVz",
  "1FjJQ-GZY_8H-u6wH6wBEcpJFdlpNB1Dg",
  "1I3rIE00K63E8yvILPBYvHbJDpujsnF9o",
  "1JAXcIJulua9KDUF0GaxdoP4WvDgXFlp6",
  "1JQQhAnNteOtndEcVRF_q1Kh1lJ3gJeN8",
  "1JfYrzrJfoHqNXRm3kZeYyVLyFvsf24fv",
  "1KNDOOfSbZHdNlF4Llel7t-h1lN5g_TY3",
  "1KUSuBPTJuMeKbPDomJhUgwFJt6zWhqPc",
  "1NCKD6aZf947YrtOlY3_SDXO6hd624dCb",
  "1O775ijbW6fBCoBpOYUS9rPYtEJb8hBmO",
  "1ORSZhy-sMBtTSGef3Euis98hsYeHFEXL",
  "1PXT1Dm22-nEitQvy44INvOgEwOU6AcyH",
  "1QWT2y3yJTC4YItOgpZLqv8FFdDf_611T",
  "1R8Dy-G7nwDmi6SHah6A8NTAKjDVf8x-r",
  "1STvLxId5Wep6Qy_s1tOWv4ZaMrtYJ3fV",
  "1Slniz7-gsHSJ7xwa-hT940GVvr_3kJvv",
  "1SwLlUlLUh8CRGFUkaezuNTrF4QO8pQvN",
  "1TIjZXwGwixRA5977XesWgHXx0Q3Uziur",
  "1V-Zc2O2wgF_6zV3v2tdYLFSLhVIIrwRF",
  "1VmzM5Yr3o6zMUD0xUQ6ji_frKw0LTA3B",
  "1WsZqwEtMtiU6BnQD5LxY2Usup9ducOB5",
  "1X_0veQ_AeTeLD1L5um9iTxIl3CBVA_Us",
  "1Z4wESTln51WzLVhNWm66JdRkkKLY8Dhg",
  "1ZGosLyfCtaBKbw7aKYJyHwX8Q930l2h_",
  "1ZrCJu4W3YlTbTaRaemZXr15LgCW8ldLl",
  "1_drUUesZF8Mnb62H-5yw1eCH3n5fLjlk",
  "1a4tJ5E0sHT584EWAtxclnz8F659tznT9",
  "1bP16UwGRT8qPbhUz3XO6cvyVVsCHJ6i8",
  "1biXm-Vr1Ts5oe1sQeoRHCo0z_kuEOIHs",
  "1bn6IOLLJJ5ihbqJ6xWQusbjbsU_tnvyh",
  "1cMtklchTha_42FXOf0MSVUCruubJRFEO",
  "1cnyLrrijbI_zUfpiVnuVwtqWGy5WKipW",
  "1dJyZyRdWj8ukfptZbdcrwg1qyD5Ilu9-",
  "1dPWbgtTCaoh5pF1cEGXoIlmZTjaqRgEz",
  "1djdGMYs_1BN-n1n16wLf-PBQfRrk9Jzh",
  "1fVg9c0L4S7C_BA4IS10Os1a5D1I8uhMA",
  "1fkQhFfYdGBHSKu3CvSa1XqG1cgMmVQUO",
  "1fwaN1jZhYc-MtYZFaEjGmoT4VpPGBD6M",
  "1fzwxU8yFK_Q1n_Ui8nH8PO-MMwd6P_fL",
  "1ghuTR0QM9AlUjyNlWQpMpVhPsTy_NbGO",
  "1hAYwiYNIu9n4Uxf3fo7y3F2KH4HkYAgN",
  "1hUHlI0jedWPEO5UWaJMPPvvy5JHeKTg3",
  "1htJgC9wHh90svUpMN-iTuOWxg4cIMI6V",
  "1iPk-gnzTA-jAA-YHQB0WMnsIJXS0sXUE",
  "1k50AiIbH1wkG67R6R77gunkjLIH2fsWw",
  "1lJqNf7Ozs-KQArHTb6r2MS6UExqAILsP",
  "1n26RAcjdfpfpExsUwDhKsOqvikcbQl31",
  "1nVhPZV9r38e7bmuC3ZlrxkkofaAUksK1",
  "1onLaXGyQg0OBsZhI9j6Zio0skq2Fw2Lm",
  "1pgzAv60pwsvml3OqJTZ8wKDV_Y2RSPtJ",
  "1pypqWEbQHzhVc5ehTALQMh28NrSPlkSV",
  "1qZykBLALh3MHpBkvbz7tyRRb7hXQC9qI",
  "1rPDKZHx4OzG0N3qTQLS4wE54_ApiLTR2",
  "1rYE3PVfNaLAkKmVQCgiXSGjOv9aXNF9B",
  "1rYisT2BSzZQEUmKjHHOWCMFYt2hZw7BQ",
  "1rdmNt7cSLGRBhfIMTiEvdmgWZ8A-lJSR",
  "1sDKEkwlq-lWYPsKoFYpkSGRVJz5vteSW",
  "1td1Ai5Bh26tVr75oRX5trBx4zKCwWE9Z",
  "1uB4dRn21g6KUztWTzHZWq4scz9ZNoWfC",
  "1uyFsCC87NDZbO6q5uYhxPOuj1kHvnPNw",
  "1wbRwg7vGRzxLmq31O1G3chSnoQgJy9C5",
  "1xCMfDZs0K5jl9jky1zQPCPh8dCG7ozsG",
  "1y_UXBnk5d0if1V9Vkn_s43SBV8Xn9--k",
  "1z3NU-9y7vVMbdVAmnDe-cd3XIPYu2lkp",
  "1zKqnyYTTOEop3_oxaXgLRVPyuoadZbny",
];

export const getSlideImages = (width) =>
  imageIds.map((id) => ({
    url: `https://drive.google.com/thumbnail?id=${id}&sz=w${width}`,
    caption: undefined,
  }));
